import React from 'react';
import Link from 'next/link';

export default function Custom404() {
  return (
    <div
      className=" flex flex-col items-start justify-start md:justify-center md:items-center md:flex-row md:space-x-6 md:mt-24 container px-2 md:px-5 py-12 h-screen w-screen"
      style={{ margin: '0 !important' }}
    >
      <div className="pt-6 pb-8 space-x-2 md:space-y-5">
        <h1 className="text-6xl font-extrabold leading-9 tracking-tight md:text-8xl md:leading-14 md:border-r-2 md:px-6 text-white">
          404 Error
        </h1>
      </div>
      <div className="max-w-md">
        <p className="mb-4 text-xl font-bold leading-normal md:text-2xl">Looks like you're lost.</p>
        <p className="mb-8">Try visiting the homepage and starting from there.</p>

        <Link href="/">
          <a className="analytics-contact-us-link primary-button mt-8 md:mt-auto md:flex space-x-1 items-center hover:text-white text-center w-2/5">
            <span className="font-bold flex items-baseline mx-auto">
              Home
              <svg
                className="w-4 h-4 ml-2 pt-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
}
